import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { Button, Columns, Container, Heading, Hero, Level, Section, Element, Icon, Form } from 'react-bulma-components'
import { StaticImage } from 'gatsby-plugin-image'
import P from '../components/elements/p'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import SingleProductThumbnail from '../components/e-commerce/single-product-thumbnail'
import { useSession } from '../stores/session'
import { useProducts } from '../stores/products'
import BrandsSection from '../components/brands/brands-section'
import { useCategories } from '../stores/categories'
import { sortBy } from 'lodash'
import CloudinaryImage from '../components/cloudinary/image'

const IndexPage = ({ data }) => {

  const { t } = useTranslation('page_home')
  const { language, navigate } = useI18next()
  const [sessionState] = useSession()
  const [productsState, productsActions] = useProducts()
  const [categoriesState, categoriesActions] = useCategories()
  React.useEffect(() => {
    if (!categoriesState.all[0])
      categoriesActions.fetch({ mode: 'tree' })
  }, [])

  const brands = sortBy(data.allBrand.edges.map(edge => edge.node), 'name')

  return (
    <Layout>

      <Section>
        <Container>
          <Hero>
            <Hero.Body>
              <Columns vCentered>
                <Columns.Column size={6}>
                  <Heading>{t('page_home:hero.title')}</Heading>
                  <Heading subtitle>{t('page_home:hero.subtitle')}</Heading>
                  {
                    data.categories.edges.map(categoryNode =>
                      <Element key={categoryNode.node._id} style={{ display: 'inline-block' }} mr={2} mb={2}>
                        <Button color='primary' outlined onClick={() => {
                          productsActions.setFilter({
                            key: 'categories',
                            value: [categoryNode.node._id],
                            clear: true,
                          })
                          navigate('/all-products')
                        }}>
                          {categoryNode.node.labels[language || 'fr']}
                        </Button>
                      </Element>
                    )
                  }
                  <Element>
                    {t('page_home:hero.text')}
                  </Element>
                </Columns.Column>
                <Columns.Column
                  justifyContent='center'
                  display='flex'
                  size={4}
                  offset={1}
                >
                  <Rotator>
                    {
                      brands.filter(b => b.visibility.public).map(brand =>
                        <div className='fadein'
                          key={brand._id}>
                          <CloudinaryImage
                            className='fadein'
                            publicId={ brand.logo.providerId }
                            format='png'/>
                        </div>
                      )
                    }
                  </Rotator>
                </Columns.Column>
              </Columns>
            </Hero.Body>
          </Hero>
        </Container>
      </Section>
      <Section backgroundColor='white-ter'>
        <Container>
          <Columns className='is-vcentered is-centered is-mobile'>
            <BrandsSection isGray/>
          </Columns>
        </Container>
      </Section>
      <Section>
        <Container>
          <Columns>
            <Columns.Column>
              <Heading size={4}>Exalto Benelux S.A.</Heading>
              <P>
                {t('page_home:section1.p1')}
              </P>
              <P>
                {t('page_home:section1.p2')}
              </P>
            </Columns.Column>
            <Columns.Column display='relative'>
              <StaticImage src='../../static/exalto-video-thumbnail.png' alt='Exalto video thumbnail' />
              <Element display='flex' justifyContent='center' alignContent='center' alignItems='center' overlay>
                <Form.Control>
                  <Button color='primary'>
                    <Icon align='left'>
                      <FontAwesomeIcon icon={faPlayCircle} />
                    </Icon>
                    <Element renderAs='span'>
                      {t('page_home:section1.watch_video')}
                    </Element>
                  </Button>
                </Form.Control>
              </Element>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
      {
        data.newnessProducts.edges[0] &&
          <Section>
            <Container>
              <Heading size={4}>{t('page_home:section-whats-new.heading')}</Heading>
              <Columns gap={6} mb={5}>
                { data.newnessProducts.edges.map(
                  edge => (
                    <Columns.Column tablet={{ size: 4 }} desktop={{ size: 3 }} key={edge.node._id}>
                      <SingleProductThumbnail product={edge.node}/>
                    </Columns.Column>
                  )
                )}
              </Columns>
            </Container>
          </Section>
      }
      <Section backgroundColor='dark'>
        <Container textColor='white'>
          <Columns>
            <Columns.Column>
              <Heading size={4}>{t('page_home:section-professionals.1-heading')}</Heading>

              <Element>{t('page_home:section-professionals.1-p')}</Element>
            </Columns.Column>
            <Columns.Column>
              <Heading size={5} textColor='white' textTransform='uppercase' textWeight='normal'>{t('page_home:section-professionals.2-heading')}</Heading>
              <Element>{t('page_home:section-professionals.2-p')}</Element>
            </Columns.Column>
            <Columns.Column>
              <Heading size={5} textColor='white' textTransform='uppercase' textWeight='normal'>{t('page_home:section-professionals.3-heading')}</Heading>
              <Element>{t('page_home:section-professionals.3-p')}</Element>
            </Columns.Column>
          </Columns>

          <Element align='center'>
            <Button color='primary' mt={4} renderAs={Link} to={ sessionState.currentUser ? '/account/dashboard' : '/login'}>{t('page_home:section-professionals.cta')}</Button>
          </Element>
        </Container>
      </Section>

      {/* <Section>
        <Container mb={3}>
          <Heading size={4}>Dernières actualités</Heading>
          <Columns gap={8}>
            <Columns.Column>
              <Element>
                <StaticImage src='../../static/mockups/storage.webp' height={250} width={500} alt="" />
                <Heading size={5} textColor='dark' textTransform='uppercase' textWeight='normal' mt={4} mb={3}>Nouvel entrepôt</Heading>
                <Element>Afin de garantir à tous nos distributeurs et partenaires un stock consistant nous venons d'ajouter +30.000m2 d'entrepôt. ...</Element>
                <Element display='flex' justifyContent='space-between' alignItems='end' mt={3}>
                  <Element textColor='grey' textWeight='bold'>
                    May 15, 2021
                  </Element>
                  <Element renderAs='a' href='#' className="exalto-link" textWeight='bold' textColor='primary'>Lire la suite</Element>
                </Element>
              </Element>
            </Columns.Column>
            <Columns.Column>
              <Element>
                <StaticImage src='../../static/mockups/scooter.webp' alt="" height={250} width={500} />
                <Heading size={5} textColor='dark' textTransform='uppercase' textWeight='normal' mt={4} mb={3}>Trottinettes connectées</Heading>
                <Element>Grâce à un nouveau partenariat nous enrichissons notre gamme de trottinettes électriques dernière génération ...</Element>
                <Element display='flex' justifyContent='space-between' alignItems='end' mt={3}>
                  <Element textColor='grey' textWeight='bold'>
                    May 2, 2021
                  </Element>
                  <Element renderAs='a' href='#' className="exalto-link" textWeight='bold' textColor='primary'>Lire la suite</Element>
                </Element>              </Element>
            </Columns.Column>
            <Columns.Column>
              <Element>
                <StaticImage src='../../static/mockups/fireworks.webp' alt="" height={250} width={500} />
                <Heading size={5} textColor='dark' textTransform='uppercase' textWeight='normal' mt={4} mb={3}>Bienvenue !</Heading>
                <Element>Ceci est notre tout nouveau site web, véritable plateforme pour nos distributeurs, il se veut pratique, connecté, efficace et...</Element>
                <Element display='flex' justifyContent='space-between' alignItems='end' mt={3}>
                  <Element textColor='grey' textWeight='bold'>
                    Apr 19, 2021
                  </Element>
                  <Element renderAs='a' href='#' className="exalto-link" textWeight='bold' textColor='primary'>Lire la suite</Element>
                </Element>
              </Element>
            </Columns.Column>
          </Columns>
        </Container>
      </Section> */}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    allBrand {
      edges {
        node {
          logo {
            providerId
          }
          visibility {
            public
          }
          name
          _id
          link
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    categories: allCategories {
      edges {
        node {
          _id
          parent {
            id
            parent {
              id
            }
          }
          labels {
            en
            fr
            nl
          }
        }
      }
    }
    newnessProducts: allProducts(filter: {visibility: {homepage: {newness: {eq: true}}}}) {
      edges {
        node {
          _id
        brand {
          _id
          name
          logo
          link
          visibility {
            public
          }
        }
        attributes {
          en
          fr
          item {
            attribute {
              _id
              family
              positionIndex
              relational
              type
              labels {
                en
                fr
                nl
              }
              dates {
                created
              }
            }
            _id
            dates {
              created
            }
            family
            relational
            type
            values {
              en
              _id
              fr
              nl
            }
            labels {
              en
              fr
              nl
            }
          }
          nl
          value {
            en
            fr
            item
            nl
          }
        }
        categories {
          _id
          labels {
            en
            fr
            nl
          }
          alternative_parent {
            labels {
              en
              fr
              nl
            }
          }
        }
        description {
          en
          fr
          nl
        }
        files {
          _id
          name {
            en
            nl
            item
            fr
          }
          path
          visibility {
            commercials
            professionals
            public
          }
        }
        kind
        linkedProducts {
          _id
        }
        mafactData {
          category
          codebar
          name {
            fr
            nl
          }
          ref
          typart
          uid
        }
        name {
          en
          fr
          nl
        }
        packaging {
          baseUnit
          byCardboard
          byPallet
          customerUnit
          length
          surface
          unit
          volume
          weight
          piecePerCardboard
        }
        pictures {
          _id
          cover
          item { 
            providerId
          }
        }
        pricing {
          auvibel
          bebat
          price
          recupel
          srp
          taxes
          vat
        }
        removedFromMafact
        stock {
          available
          clientOrders
          providerOrders
          total
        }
        visibility {
          professionals
          public
          homepage {
            newness
            promotion
          }
        }
        }
      }
    }
  }
`

const Rotator = ({ children }) => {

  const [index, setIndex] = useState(0)

  useEffect(
    () => {
      if (index === (children.length - 1)) {
        setTimeout(() => setIndex(0), 5000)
      }
      else {
        const newIndex = index + 1
        setTimeout(() => setIndex(newIndex), 5000)
      }
    },
    [children, index]
  )

  return (
    children[index]
  )
}
